import React, { useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';

const StyledForm = styled.form`
  width: 400px;
  text-align: center;

  input {
    padding: 5px;
    border-radius: 5px;
  }
`;

function debounce(func, wait) {
  let timeout;
  return function executedFunction() {
    const context = this;
    const args = arguments;
    const later = function() {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }
}

const search = debounce(keywords => navigate(`/search?keywords=${encodeURIComponent(keywords)}`), 300);

const SearchForm = ({ query }) => {
  const [keywords, setKeywords] = useState(query);
  const handleChange = e => {
    const { value } = e.target;
    setKeywords(value);
    return search(value);
  };
  return (
    <StyledForm role="search" method="GET">
      <label htmlFor="search-input">
        <h1>Search Articles</h1>
      </label>
      <input
        id="search-input"
        type="search"
        name="keywords"
        aria-controls="search-results-count"
        onChange={handleChange}
        value={keywords}
        placeholder="Search away"
      />
    </StyledForm>
  );
};

export default SearchForm;
