import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import 'url-search-params-polyfill';

import Header from '../components/Header';
import SEO from '../components/SEO';
import Footer from '../components/Footer';
import SearchForm from '../components/SearchForm';
import SearchResults from '../components/SearchResults';

const StyledContainer = styled.div`
  padding: 90px 5px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 40px);
`;

const StyledLayout = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Search = ({ location }) => {
  const [results, setResults] = useState([]);
  const searchQuery = new URLSearchParams(location.search).get('keywords') || '';

  useEffect(() => {
    if (searchQuery && window.__LUNR__) {
      const searchArticles = async () => {
        const lunr = await window.__LUNR__.__loaded;
        const refs = lunr.en.index.search(searchQuery);
        const posts = refs.map(({ ref }) => lunr.en.store[ref]);
        setResults(posts);
      };
      searchArticles();
    }
    if (!searchQuery) setResults([])
  }, [location.search]);

  return (
    <Fragment>
      <SEO />
      <Header />
      <StyledContainer>
        <StyledLayout>
          <SearchForm query={searchQuery} />
          <SearchResults query={searchQuery} results={results} />
        </StyledLayout>
      </StyledContainer>
      <Footer />
    </Fragment>
  )
}

export default Search
