import React from 'react';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';

import media from '../utils/breakpoints';
import { getPath } from '../utils/helpers';
import { StyledPost, StyledContent as _StyledContent } from '../components/NewsItem';

const StyledContent = styled(_StyledContent)`
  padding: 0;
  ${media.desktop`padding: 0;`}
`;

const StyledResults = styled.section`
  .count {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 14px;
  }
  .list {
    list-style: none;
    padding: 0;
    .heading {
      margin-bottom: 5px;
    }
    .link {
      box-shadow: none;
    }
  }
`;

const SearchResults = ({ query, results }) => (
  <StyledResults aria-label="Search results for all posts">
    {!!results.length && query && (
      <h2
        className="count"
        id="search-results-count"
        aria-live="assertive"
      >
        Found {results.length} posts on "{query}"
      </h2>
    )}
    {!!results.length && (
      <ol className="list">
        {results.map(({ title, url, date, spoiler }) => (
          <StyledPost to={getPath(url)}>
            <StyledContent>
              <h2>
                <Highlighter
                  searchWords={[query]}
                  autoEscape={true}
                  textToHighlight={title}
                  highlightClassName="highlighter"
                />
              </h2>
              <small>
                {date}
              </small>
              {spoiler && (
                <p>
                  <Highlighter
                    searchWords={[query]}
                    autoEscape={true}
                    textToHighlight={spoiler}
                    highlightClassName="highlighter"
                  />
                </p>
              )}
              <p className="more">READ MORE →</p>
            </StyledContent>
          </StyledPost>
        ))}
      </ol>
    )}
  </StyledResults>
)

export default SearchResults;
