import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { formatReadingTime, getPath } from '../utils/helpers';
import media from '../utils/breakpoints';

export const StyledPost = styled(Link)`
  flex-direction: column;
  max-width: 400px; 
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  text-decoration: inherit;
  color: inherit;
  &:last-child {
    margin-bottom: 0px;
  }
  ${media.desktop`
    max-width: inherit;
    flex-direction: row;
  `}
  &:hover .more {
    text-decoration: underline;
  }
  .highlighter {
    background-color: #BFD556;
  }
`;

export const StyledContent = styled.section`
  flex-grow: 1;
  padding: 10px;
  h2 {
    font-family: brother-1816, sans-serif;
    font-weight: 700;
    margin: 0;
  }
  ${media.desktop`padding: 30px;`}
  color: #231F20;
`;

const StyledThumbbail = styled.div`
  flex: 0 0 auto;
  width: 400px;
  height: 250px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const NewsItem = ({ title, url, image, date, timeToRead, spoiler }) => (
  <StyledPost to={getPath(url)}>
    { image && (
      <StyledThumbbail>
        <img src={image} alt="" />
      </StyledThumbbail>
    )}
    <StyledContent>
      <h2>{title}</h2>
      <small>
        {date}
        {` • ${formatReadingTime(timeToRead)}`}
      </small>
      <p
        dangerouslySetInnerHTML={{ __html: spoiler }}
      />
      <p className="more">READ MORE →</p>
    </StyledContent>
  </StyledPost>
);

NewsItem.defaultProps = {
  highlight: undefined,
};

export default NewsItem;
